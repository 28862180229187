.wrapper {
  padding: 40px 0;
}

.content{
  padding: 40px 0;
  h1,h2,h3, {

  }
}

blockquote {
  position: relative;
  margin-left: 30px;

  &:after {
    content: '';
    width: 45px;
    height: 45px;
    background: url('assets/images/quotes.png');
    background-size: cover;
    position: absolute;
    top: 10px;
    left: -35px;
  }
}

.list-item-style {
  list-style: none;

  li {
    position: relative;
    line-height: 30px;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background: $primary-color;
      position: absolute;
      left: -20px;
      top: 10px;
      border-radius: 50%;
    }
  }
}

.head-title {
  text-transform: uppercase;
  color: $primary-color;
  font-size: 15px;
}

.link-to {
  color: $color-seagull;
}

.item-list{
  .column--border_right {
    border-right: 1px solid $color-boulder;

    &:first-child {
      text-align: left;
    }
    &:last-child {
      border: none;
      text-align: right;
    }
  }
}


// Page Contact
.contact {
  .form-horizontal {
    margin: 30px 0 30px;
  }

  .contact-description{
    margin: 30px 0 60px;
  }

  p {
    margin-bottom: 40px;

    &.has-error,
    &.show-status {
      margin: 0;
      padding: 0;
      margin-right: 5px;
      max-width: 100%;
    }

    &.has-error {
      color: $color-milano-red;
    }

    &.show-status {
      color: $color-bondi-blue;
    }
  }

  .data-phone {
    margin-top: 30px;

    .fa-phone, .phone {
      display: inline-block;
      vertical-align: top;
    }

    .fa-phone{
      color: $color-blizzard-blue;
      padding-right: 20px;
    }

    .phone {
      width: 100px;
      color: $color-black;
    }
  }

  @media (max-width: $screen-xs-max) {
    .form-horizontal,
    .data-phone {
      margin-top: 25px;
    }
  }
}
