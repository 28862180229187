/**
 * Bootstrap customization
 * This gets loaded __after__ Bootstrap's variables.scss
 * Use this to set your custom 'derived' variables, i.e.
 * variables that don't depend on Bootstrap's default variables
 */


//== Scaffolding
$body-bg: #f7f7f7;
// Global text color for `<body>`
$text-color: $secondary-text-lbg;

// Global textual link color.
$link-color:            $primary-color;
// Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 12%);


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-base:        $font-base;


//== Buttons
$btn-border-radius-base: 3px;
$btn-border-radius-large: 3px;
$btn-border-radius-small: 3px;

$btn-default-color:              $color-white;
$btn-default-bg:                 $color-mine-shaft;
$btn-default-border:             darken($color-black, 2%);

$btn-primary-color:              $color-mine-shaft;
$btn-primary-bg:                 darken($primary-color, 15%);
$btn-primary-border:             darken($btn-primary-bg, 2%);

$btn-secondary-color:            $color-white;
$btn-secondary-bg:               darken($color-boulder, 10%);
$btn-secondary-border:           darken($btn-secondary-bg, 2%);


//== Navbar
$navbar-height: 40px;


//== Forms

// `<input>` background color
$input-bg:                      transparent;
// Text color for `<input>`s
$input-color:                    $color-boulder;
// `<input>` border color
$input-border:                   $color-boulder;
//** Placeholder text color
$input-color-placeholder:        $color-boulder;
