.ribon-footer {
  background-color: $color-very-dark-gray;
  padding: 18px 0;
  margin-top: 71px;

  p {
    margin: 0;
    max-width: initial;
    text-align: center;
  }

  a {
    color: $color-grayish-blue;

    &:active,
    &:focus,
    &:hover,
    &:visited {
      color: $color-grayish-blue;
      text-decoration: underline;
    }
  }
}
