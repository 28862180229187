//
// Fonts
// --------------------------------------------------

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500; 
}

.font-regular {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}
