.tags {
  color: $color-morning-glory;
  
  a, .fa-tag {
  	color: $color-morning-glory;
  }

  .fa-tag {
    padding-right: 5px;
  }
}