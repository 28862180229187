//
// Title and subtitle
// --------------------------------------------------

.Title {
  border-bottom: 2px solid $primary-color;
  color: $primary-text-lbg;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 0 40px 20px;
  text-transform: uppercase;

  &--left {
    padding-left: 0;
  }
}
