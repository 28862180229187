//
// Buttons
// --------------------------------------------------

.btn {
  box-shadow: inset 0 5px 6px -3px rgba($color-white, 0.4); 
  font-weight: 500;
  text-transform: uppercase;
  @include button-size(8px, 30px, $font-size-base, $line-height-base, $btn-border-radius-base);
}

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}
