.articles{
  &__description {
    margin: 2em 0;
  }

  &__wrapper {
    border-bottom: solid 1px #ccc;
  }

  &__data{
    max-width: 770px;
    margin: 0 auto;
  }

  &__item {
    padding: 28px 0;
  }

  &__title-item {
    text-transform: uppercase;
    font-size: 16px;
    color: $color-black;
    margin-top: 0;
    line-height: 23px;
  }

  &__thumb-item {
    position: relative;
    width: 176px;
    height: 176px;
  }

  &__button{
    padding: 35px 0;
  }

  &__single_articles_tag {
    .articles__single{
      .no-padding{
       padding: 0;
      }
    }
  }

  &__single {
    h3, h4{
      color: $color-black;
    }

    h3{
      margin-bottom: 30px;
    }
    @media (max-width: $screen-xs-max) {
      .no-padding{
        padding: 0 15px
      }
    }
  }

  &__single_sidebar_data {
    margin-bottom: 30px;
  }

  &__single_date {
    padding: 25px 10px;
    background-color: $primary-color;
    margin-bottom: 30px;
    span {
      font-size: 18px;
      color: $color-black;
      font-weight: 500;
    }
  }

  &__single_databar{
    overflow: hidden;
    border-top: solid 1px $color-black;
    border-bottom: solid 1px $color-black;
    padding: 10px 0;
    margin: 40px 0;
  }

  @media (max-width: $screen-xs-max) {
    .articles__thumb-item {
      margin: 0 auto 40px;
      height: auto;
    }
  }

  &__date {
    width: 100px;
    height: 77px;
    position: static;
    top: 0;
    left: -100px;
    padding: 5px 0;
    background-color: $primary-color;

    span {
      display: block;
      color: $color-black;
      font-size: 16px;
      line-height: initial;
      text-transform: uppercase;
    }

    .number {
      font-size: 36px;
    }
  }

  @media (max-width: 414px) {
    .articles__date {
      position: initial;
      margin: auto;
    }
  }
}
