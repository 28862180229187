html, body, #root {
  margin: 0;
  height: 100%;
}

body, h1, h2, h3 {
  font-weight: 300
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    color: #5aadbb;
  }
}

@import 'app/components/styles/theme/buttons';
@import 'app/components/styles/theme/navbar';
@import 'app/components/styles/theme/carousel';
@import 'app/components/styles/theme/title';
@import 'app/components/styles/theme/forms';
@import 'app/components/styles/theme/fonts';
@import 'app/components/styles/theme/display-table';
@import 'app/components/styles/theme/informative-label';
@import 'app/components/styles/theme/associate';
@import 'app/components/styles/theme/card';
@import 'app/components/styles/theme/pages';

@import 'app/components/styles/components/services';
@import 'app/components/styles/components/clients';
@import 'app/components/styles/components/map';
@import 'app/components/styles/components/articles';
@import 'app/components/styles/components/tags';
@import 'app/components/styles/components/button';
@import 'app/components/styles/components/headers-sections';
@import 'app/components/styles/components/social-share';

@import 'app/components/styles/layout/footer';
@import 'app/components/styles/layout/layout';
@import 'app/components/styles/layout/ribbon-footer';
