//
// Navbar
// --------------------------------------------------

.navbar {
  margin-bottom: 0;
}

.navbar-default {
  background-color: $color-white;
  border: 0;
  font-size: 12px;
  padding-top: 22px;
  padding-bottom: 5px;
  text-transform: uppercase;

  .navbar-nav {
    & > .active > a,
    & > .active > a:hover,
    & > .active > a:focus {
      background-color: transparent;
    }

    & > li {
      &:hover,
      &.active {
        box-shadow: 0 5px 0 $color-very-soft-blue;
        z-index: 1;
      }
    }
  }

  .clients-item > a {
    color: $color-very-soft-blue !important;
  }

  .header-logo {
    background-color: $color-white;
    left: 45%;
    padding: 16px;
    position: absolute;
    z-index: 1;
  }
}

@media(max-width: $screen-sm) {
  .header-logo {
    display: none;
  }

  .navbar-collapse {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 12px;
  }

  .navbar-header .navbar-brand:hover {
      background: $color-white;
   }
}

@media(max-width: $screen-md) {
  .navbar-default {
    padding: 2px;

    .navbar-collapse {
      margin-left: 109px;
      margin-right: -25px;
      font-size: 11px;
    }

    .header-logo {
      left:0;
    }
  }
}

.logo-navbar-toggle {
  background: $color-white;
  position: absolute;
  z-index: 1000;
  width: 80px;
  height: 80px;
  margin-left: -2px;
}
