//
// Carousel
// --------------------------------------------------
//
.carousel {
  .carousel-inner {
    height: auto;
  }

  .carousel-indicators li {
    background-color: white;
    border-radius: 0px;
    opacity:0.6;

    &.active {
      opacity: 1;
    }
  }

  .carousel-caption {
    top: 130px;
  }

  .item > img {
    width: 100%;
  }

  .item-subtitle {
    font-size: 20px;
    font-weight: lighter;
    margin-bottom: 40px;
  }

  .item-title {
    font-size: 44px;
    font-weight: 600;
    text-transform: uppercase;
  }

  @media(max-width: $screen-md) {
    .carousel-caption {
      top: 50%;
      transform: translate(0%,-50%);
    }

    .item-title {
      font-size: 20px;
    }
  }

  @media(max-width: $screen-sm) {
    .item-title {
      font-size: 16px;
    }

    .item-subtitle {
      font-size: 14px;
    }
  }
}
