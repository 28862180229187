.services {

  &__btn {
    margin-top: 20px;

    @media screen and (max-width: $screen-sm) {
      margin-bottom: 1.5em;
    }
  }

  &__description {
    margin-bottom: 3em;
    margin-top: 3em;
  }


  &__title-post {
    color: $primary-color;
    margin-bottom: 2em;
    text-transform: uppercase;

    @media screen and (max-width: $screen-sm) {
      margin-bottom: 1em;
      margin-top: 1em;
    }
  }

}

