.clients {
  background-color: lighten($color-alto, 8%);
  margin-top: 4em;
  padding-bottom: 2.5em;
  padding-top: 2em;

  &__description {
    margin-bottom: 2em;
  }

  &__image {
    display: inline-block;
    margin: 0 15px;
    vertical-align: middle;
  }

  &__item {
    &:first-child {
      .clients__image {
        margin-left: 0;
      }
    }

    &:last-child {
      .clients__image {
        margin-right: 0;
      }
    }
  }
}
