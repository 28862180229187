.gmap {
  height: 0;
  overflow: hidden;
  // padding-bottom: 75%;
  position: relative;

  &__canvas {
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }

  &.gmap-contact{
    height: 320px;
  }
}
