//
// Forms
// --------------------------------------------------

.form-control {
  box-shadow: none;
}

textarea.form-control {
  height: 100px;
}

@media (max-width: $screen-xs-max) {
  input.form-control{
      margin-bottom: 15px;
  }
}