//
// Footer
// --------------------------------------------------

footer {
  background-color: $color-shark;
  color: $color-very-light-gray;
  padding-top: 60px;
  position: relative;
  text-align: center;
  width: 100%;
  overflow: hidden;

  .Title {
    color: $color-white;
  }

  p {
    max-width: 453px;
    margin-bottom: 32px;

    &.has-error,
    &.show-status {
      margin: 0;
      padding: 0;
      margin-right: 5px;
      max-width: 100%;
    }

    &.has-error {
      color: $color-marigold;
    }

    &.show-status {
      color: $color-seagull;
    }
  }
  .form-description{
    color: $disable-hint-dbg;
  }
  .form-horizontal {
    margin: 30px 0 30px;

    a {
      font-weight: 500;
      color: $color-white;
    }
  }

  .form-contact {
    .form-control {
      background: $color-boulder;
      border: transparent;
      color: $color-white;
      &::-webkit-input-placeholder
      {
        color: $color-white;
      }
      &::-moz-placeholder{
        color: $color-white;
      }
    }
  }

  .fa {
    color: $color-blizzard-blue;
    padding-right: 10px;
  }

  .gmap {
    height: 280px;
    padding-bottom: 0;
  }
}
