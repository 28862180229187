/**
 * Bootstrap customization
 * This gets loaded __before__ Bootstrap's variables.scss
 * Use this to set your custom 'primary' variables, i.e.
 * variables that don't depend on Bootstrap's default values
 */

//== Colors

$color-blizzard-blue: #bddaf2;
$color-baltic-sea: #312E34;
$color-black: #000;
$color-grayish-blue: #a4bccf;
$color-very-light-gray: #dfdfdf;
$color-very-dark-gray: #424242;
$color-very-soft-blue: #bddaf2;
$color-morning-glory: #93badb;
$color-shark: #313132;
$color-mine-shaft: lighten($color-black, 13%);
$color-boulder: lighten($color-black, 46%);
$color-silver-chalice: lighten($color-black, 62%);
$color-white: #fff;
$color-alto: darken($color-white, 13%);
$color-nobel: darken($color-white, 30%);
$color-gray: darken($color-white, 50%);
$color-marigold: #c38f2f;
$color-seagull: #7db6e5;
$color-milano-red: #c20909;
$color-bondi-blue: #0292aa;


//== UI Colors

$primary-color: $color-blizzard-blue;
$accent-color: $color-black;
//## Text color light backgrounds
$primary-text-lbg: $color-mine-shaft;
$secondary-text-lbg: $color-boulder;
$disable-hint-lbg: $color-silver-chalice;
//## Text color dark backgrounds
$primary-text-dbg: $color-alto;
$secondary-text-dbg: $color-nobel;
$disable-hint-dbg: $color-silver-chalice;

//== Fonts

$font-base: 'Raleway', sans-serif;
