.associate{
  // Associate items
  &-item {
    position: relative;

    &__head {
      position: relative;

      @media screen and (min-width: $screen-md-max) {
        height: 200px;
      }
    }

    &__image {
      position: absolute;
      display: block;
      width: 230px;
      height: 230px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      filter: grayscale(100%);
      transition: all .3 ease;

      @media screen and (max-width: $screen-md-max) {
        position: relative;
      }
    }

    &:hover {
      .associate-item__image {
        @media screen and (min-width: $screen-md-max) {
          top: 30%;
          transition: all .5s ease;
          filter: none;
        }
      }
    }

    &__name {
      color: $primary-text-lbg;
      font-size: 16px;
      font-weight: 700;
      margin: 40px 0 10px 0;
    }

    &__lawyer-description {
      color: $color-grayish-blue;
      display: block;
      font-style: italic;
      margin-bottom: 10px;
    }

    &__name,
    &__description,
    &__lawyer-description {
      text-align: center;
    }

    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      padding: 10px 15px;
      font-size: 25px;
      color: $color-blizzard-blue;
      transition: all .2s ease-in-out;

      &:hover {
        color: white;
        background-color: $color-blizzard-blue;
      }
    }
  }
  // Blocks of associates items
  &__block {
    margin-top: 80px;

    .Title {
      margin-bottom: 50px;
    }

    &__list {
      margin-bottom: 40px;
    }
    .carousel-indicators li {
      background-color: $color-gray;
      border-radius: 0px;
      opacity:0.6;
      margin-bottom: -50px;

      &.active {
        opacity: 1;
        margin-bottom: -50px;
      }
    }
  }
}
