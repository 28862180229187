.tm-table {
  display: table;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  width: 100%;

  &-cell {
    display: table-cell;
    float: none;

    @media screen and ( max-width: $screen-sm) {
      display: block;
      width: 100%;
    }
  }
}

.tm-middle {
  vertical-align: middle;
}
