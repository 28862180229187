.banner{
  background-size: cover;
  background-position: center center;
  height: 220px;

  h2 {
    color: $color-white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 40px;
    margin-top: 140px
  }
}