html,
body {
  height: 100%;
  min-height: 100%;
}

#app {
  .section-content {
    min-height: 100%;
  }
}

.no-padding{
  padding: 0;
}

.padding-right{
  padding-right: 0;
}

.padding-top-40{
  padding-top:40px;
}

hr.separator-line{
  width: 100%;
  float: left;
  height: 1px;
  margin: 55px 0;
  background-color: $color-black;
}

