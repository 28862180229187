.card {
  display: flex;
  text-align: left;

  &:not(:last-of-type) {
    margin-bottom: 55px;
  }
}

.card__avatar {
  width: 65px;
  height: 65px;
  margin-right: 30px;
}

.card__name {
  color: $color-black;
  font-size: 16px;
}

.card__position {
  color: $color-blizzard-blue;
}
