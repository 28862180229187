.informative-label {
  align-items: center;
  background-color: $color-baltic-sea;
  color: $color-white;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  height: 100px;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  p{
    font-size: 18px;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }

  &--button {
    background-color: $primary-color;

    a {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
